<template>
  <div class="dealers">
    <div class="contentWrapper">
      <h1>Dealers</h1>
      <div class="dealersContent">
        <div class="dealer">
          <div class="country">Argentina</div>
          <a target="_blank" href="http://www.alpaelectronica.com.ar/">
            Alpa Electronica
            <span class="icon-link-ext"></span>
          </a>
        </div>
        <div class="dealer">
          <div class="country">Australia</div>
          <a target="_blank" href="https://freedspace.com.au/">
            Freedspace
            <span class="icon-link-ext"></span>
          </a>
        </div>
        <div class="dealer">
          <div class="country">Canada</div>
          <a
            target="_blank"
            href="http://www.extremegamingdevices.com/egd_detailed_specs/naturalpoint_trackir_5.html"
          >
            Extreme Gaming Devices
            <span class="icon-link-ext"></span>
          </a>
        </div>
        <div class="dealer">
          <div class="country">Czech Republic</div>
          <a
            target="_blank"
            href="https://www.tntrade.cz/produkty-vyhledani?_Vyrobce=NATURALPOINT"
          >
            TN Trade
            <span class="icon-link-ext"></span>
          </a>
        </div>
        <div class="dealer">
          <div class="country">Finland</div>
          <a
            target="_blank"
            href="http://www.vpd.fi/catalogsearch/result/?q=natural+point"
          >
            VPD Pelikauppa
            <span class="icon-link-ext"></span>
          </a>
        </div>
        <div class="dealer">
          <div class="country">France</div>
          <a target="_blank" href="http://www.trackir.fr/">
            TrackIR France
            <span class="icon-link-ext"></span>
          </a>
        </div>
        <div class="dealer">
          <div class="country">Germany</div>
          <a target="_blank" href="http://www.2connect.eu/">
            2Connect
            <span class="icon-link-ext"></span>
          </a>
        </div>
        <div class="dealer">
          <div class="country">Japan</div>
          <a target="_blank" href="http://msygroup.com/topics/naturalpoint/">
            MSY
            <span class="icon-link-ext"></span>
          </a>
        </div>
        <div class="dealer">
          <div class="country">New Zealand</div>
          <a
            target="_blank"
            href="http://www.firstgames.co.nz/trackir-5-pro-trackclip-pro-bundle-virtual-reality-head-mouse-pc/"
          >
            First Games
            <span class="icon-link-ext"></span>
          </a>
          <a target="_blank" href="https://freedspace.com.au/">
            Freedspace
            <span class="icon-link-ext"></span>
          </a>
        </div>
        <div class="dealer">
          <div class="country">Poland</div>
          <a target="_blank" href="http://www.pilots.pl/">
            Pilots PL
            <span class="icon-link-ext"></span>
          </a>
        </div>
        <div class="dealer">
          <div class="country">Spain</div>
          <a target="_blank" href="http://www.coolmod.com/">
            Coolmod
            <span class="icon-link-ext"></span>
          </a>
        </div>
        <div class="dealer">
          <div class="country">Taiwan</div>
          <a target="_blank" href="https://realflightshop.com/en-us/">
            Real Flight Shop
            <span class="icon-link-ext"></span>
          </a>
        </div>
        <div class="dealer">
          <div class="country">Thailand</div>
          <a target="_blank" href="https://thinkofsim.com">
            Think Of Sim
            <span class="icon-link-ext"></span>
          </a>
        </div>
        <div class="dealer">
          <div class="country">United Kingdom</div>
          <a target="_blank" href="https://trackir.co.uk">
            2connect
            <span class="icon-link-ext"></span>
          </a>
        </div>
        <div class="dealer">
          <div class="country">United States</div>
          <router-link to="/trackir">NaturalPoint</router-link>
          <a target="_blank" href="https://askergoworks.com/">
            ErgoWorks
            <span class="icon-link-ext"></span>
          </a>
          <a target="_blank" href="http://www.pcaviator.com/">
            PC Aviator
            <span class="icon-link-ext"></span>
          </a>
          <a target="_blank" href=" https://thernh.com/products/trackir-5-premium-head-tracking-for-gaming?_pos=1&_sid=8dbdb34c8&_ss=r">
            The RNH
            <span class="icon-link-ext"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- END dealers -->
</template>
<script>
export default {
  name: "Dealers",
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.dealers {
  text-align: center;
  background-position: 50% 95px;
  background-size: 1200px auto;
  background-image: url($images-url + "dealers-map.png");
  background-repeat: no-repeat;
  padding: 70px 0;
}
.dealersContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.dealer {
  box-sizing: border-box;
  width: 100%;
  margin: 30px 0;

  @include for-medium-up {
    width: 33%;
  }

  a {
    display: block;
  }
}

.country {
  font-weight: bold;
  color: #ffffff;
}
</style>
